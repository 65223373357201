import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { ExpandMore } from '@mui/icons-material';
import PlaceIcon from '@mui/icons-material/Place';
import { UserContext } from '../../providers/UserProvider';
import React, { useRef } from 'react';

const openedMixin = (theme) => ({
    width: 'fit-content',
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop })(
    ({ theme, open }) => ({
        width: 'fit-content',
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

export const DrawerMenu = ({ setSelectedSite }) => {
    const { allowedSites } = React.useContext(UserContext);
    const [siteDropdownOpen, setSiteDropdownOpen] = React.useState(true);
    const buttonRef = useRef();

    const expandSiteOptions = () => {
        setSiteDropdownOpen(!siteDropdownOpen);
    
    };

    return (
        <Drawer variant="permanent" role="menu" aria-orientation='horizontal' aria-label='nav-menu' open={true} PaperProps={{ sx: { backgroundColor: '#d8dce3', boxShadow: 4 } }}>
            <Toolbar />
            <List>
                <ListItemButton onClick={expandSiteOptions} sx={{
                    pt: 2.5,
                    '&:hover, &:focus': { '& svg': { opacity: 1 } },
                }}>
                    <ListItemIcon sx={{ mr: '16px', minWidth: 0 }}>
                        <PlaceIcon sx={{ color: '#013c4a' }} />
                    </ListItemIcon>
                    <ListItemText primary="Sites" primaryTypographyProps={{ color: '#013c4a', fontWeight: 'bold', fontSize: 20, lineHeight: '20px' }} sx={{ display: 'block' }} />
                    <ExpandMore
                        sx={{
                            opacity: 0,
                            ml: '16px',
                            transform: siteDropdownOpen ? 'rotate(-180deg)' : 'rotate(0)',
                            transition: '0.2s',
                        }}
                    />
                </ListItemButton>
                {siteDropdownOpen &&
                    allowedSites.map((site) => (
                        (
                            <ListItemButton
                                key={site.id}
                                ref={buttonRef}
                                sx={{
                                    paddingY: 0, minHeight: 32
                                }}
                                onClick={() => {
                                    setSelectedSite(site)
                                    buttonRef.current.focus()
                                }}
                            >
                                <ListItemText primary={site.displayName} primaryTypographyProps={{ color: '#013c4a', fontSize: 16, fontWeight: 'medium', display: 'block' }} inset />
                            </ListItemButton>
                        )
                    ))
                }
            </List>
        </Drawer>
    )
}