import { Outlet, Navigate } from 'react-router-dom'
import { authService } from '../services/Services'


const PrivateRoutes = () => {
    const foo = authService.getIdentityToken()
    return (
        authService.getIdentityToken() ? <Outlet/> : <Navigate to="/login"/>
    )
}

export default PrivateRoutes