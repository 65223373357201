import styled from "@emotion/styled";
import TableRow from '@mui/material/TableRow';

export const TableRowStyled = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: #ffffff;
  }
  &:nth-of-type(even) {
    background-color: #d8dce333;
  }
`;