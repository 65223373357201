import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import { UserContext } from '../../providers/UserProvider';
import { DrawerMenu } from '../Drawer/Drawer';
import AccountMenu from '../AccountMenu/AccountMenu';
import { Toolbar } from '@mui/material';

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop,
})(({ theme, open }) => ({
    display: "flex",
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "#d8dce3",
}));

const logo = require('../../assets/InnovaseaLogo.png')

export default function Navbar({ setSelectedSite }) {
    const { userDetails, allowedSites } = React.useContext(UserContext);

    React.useEffect(() => {
        if (allowedSites && allowedSites.length > 0) {
            if(localStorage.getItem("selected_site")){
               const site = allowedSites.find((item) => item.name === localStorage.getItem("selected_site"))
               if(site){
                setSelectedSite(site)
               }else{
                setSelectedSite(allowedSites[0])
               }
            }else{
                setSelectedSite(allowedSites[0])
            }
            console.log("defaultSite", allowedSites[0]);
        }
    }, [userDetails, allowedSites])

    return (
        <Box sx={{ display: 'flex' }}>
            <AppBar position="fixed" open={true}>
                <Toolbar>
                    <img src={logo} height='50px' />
                    {
                        userDetails ?
                            <Box
                                sx={{ marginLeft: 'auto' }}>
                                <AccountMenu />
                            </Box> : null}
                </Toolbar>
            </AppBar>
            {
                userDetails ?
                    (
                        <DrawerMenu setSelectedSite={setSelectedSite}></DrawerMenu>
                    ) : null
            }

        </Box>
    );
}