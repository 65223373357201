import ReactApexChart from "react-apexcharts";
import "./Graph.css";

const Graph = ({ options, series = [], height = 400, width = "97%" }) => {
    const validSeries = series?.length > 0 ? series : [{ data: [] }];
    const safeOptions = {
        colors: ["#48A3A1", "#8BD4DF", "#043C4A", "#BFE3D6"],
        ...options,
    };

    return (
        <ReactApexChart
            options={safeOptions}
            series={validSeries}
            height={height}
            width={width}
            type={options.chart.type}
            key={`${options.chart.type}-${validSeries.length}`}
        />
    );
};

export default Graph;
