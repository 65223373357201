import { Box, Typography, Toolbar, Button, Tooltip } from "@mui/material";
import { useEffect, useState, useContext } from "react";
import moment from "moment-timezone";
import { deviceIsValid } from "../../../utils/Validation";
import { AppConfig } from "../../../appConfig";
import Graph from "../../../components/Graphs/Graph";
import { v1TaglessService } from "../../../services/Services";
import { calculateCumulativeValues } from "./utils";
import { CSVLink } from "react-csv";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { Direction } from "../../../types/Direction";
import {
    CountType,
    scalingBiasCorrection,
    cumulativeSum,
} from "../../../types/Count";
import { SummaryTable } from "./Table/SummaryTable";
import { PipelineMetricMap } from "../../../types/Pipeline";
import { getCSVDataFromBoundedData } from "./tsutils";
import { UserContext } from "../../../providers/UserProvider";

const SummaryReport = ({ devices, setErrorState, site, enableCI }) => {
    const { timezone } = useContext(UserContext);
    const [boundedData, setBoundedData] = useState();
    const [isCSVExportEnabled, setIsCSVExportEnabled] = useState(false);
    const [
        {
            hourlySeries,
            cumulativeNetSeries,
            cumulativeConfidenceIntervalSeries,
        },
        setSeries,
    ] = useState({
        hourlySeries: [],
        cumulativeNetSeries: [],
        cumulativeConfidenceIntervalSeries: [],
    });
    const [isLoading, setLoading] = useState(true);
    const { seasonStart, seasonEnd } = useContext(AppConfig);

    const baseChartOptions = {
        animations: {
            enabled: false,
        },
        noData: {
            text: "Loading...",
            align: "center",
        },
        stroke: {
            curve: "smooth",
        },
        xaxis: {
            type: "datetime",
            title: {
                text: "Timestamp",
                style: {
                    color: "#000000",
                    fontSize: "15px",
                },
            },
            labels: {
                datetimeFormatter: {
                    year: "yyyy",
                    month: "MMM 'yy",
                    day: "dd MMM",
                    hour: "HH:mm",
                },
                datetimeUTC: false,
            },
            padding: {
                top: 0,
                right: 50,
                bottom: 0,
                left: 50,
            },
        },
        dataLabels: {
            enabled: false,
        },
        yaxis: {
            labels: {
                style: {
                    colour: "#000000",
                    fontSize: "15px",
                },
            },
            title: {
                text: "Net Cumulative Counts",
                style: {
                    color: "#000000",
                    fontSize: "15px",
                },
            },
        },
    };

    const cumulativeChartOptions = {
        ...baseChartOptions,
        chart: {
            type: enableCI ? "rangeArea" : "line",
            animations: {
                enabled: false,
            },
            toolbar: {
                tools: {
                    reset: false,
                },
            },
        },
        stroke: {
            width: enableCI ? [2, 2, 0] : 2,
        },
        fill: {
            opacity: enableCI ? [1, 1] : 1,
        },
        tooltip: {
            x: {
                formatter: function (val) {
                    return moment
                        .utc(val)
                        .tz(timezone)
                        .format("DD/MM/YYYY HH:mm:ss");
                },
            },
            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                const sortedSeries = w.config.series.sort((x, y) =>
                    x.name.localeCompare(y.name),
                );

                const formatRangeAreaTooltip = (
                    currentSeries,
                    currentDataPoint,
                ) => {
                    const lower = currentDataPoint.y?.[0]?.toFixed(1); // net upper bound
                    const predicted = currentDataPoint.predicted?.toFixed(1); // net predicted
                    const upper = currentDataPoint.y?.[1]?.toFixed(1); // net lower bound

                    if (lower == null || upper == null) {
                        return null;
                    }

                    return `<div><b>${currentSeries.name.replace("Bounds", "")}</b>: Lower Bound ${lower}, Predicted ${predicted}, Upper Bound ${upper}</div>`;
                };

                const formatLineChartTooltip = (
                    currentSeries,
                    currentDataPoint,
                ) => {
                    const total = currentDataPoint.y?.toFixed(1);
                    const upstream = currentDataPoint.upstream?.toFixed(1);
                    const downstream = currentDataPoint.downstream?.toFixed(1);

                    const parts = [];
                    if (total != null) parts.push(`Net: ${total}`);
                    if (upstream != null) parts.push(`Upstream: ${upstream}`);
                    if (downstream != null)
                        parts.push(`Downstream: ${downstream}`);

                    if (parts.length === 0) {
                        return null;
                    }

                    return `<div><b>${currentSeries.name}</b>: ${parts.join(", ")}</div>`;
                };

                const groupedTooltip = sortedSeries.map(
                    (currentSeries, index) => {
                        const currentDataPoint = currentSeries.data?.[
                            dataPointIndex
                        ] || { y: null };
                        if (enableCI) {
                            // Handle rangeArea series
                            return formatRangeAreaTooltip(
                                currentSeries,
                                currentDataPoint,
                            );
                        } else {
                            // Handle line or other series types
                            return formatLineChartTooltip(
                                currentSeries,
                                currentDataPoint,
                            );
                        }
                    },
                );

                // Filter out null tooltips and join the valid ones
                return `<div class="custom-tooltip">
                    ${groupedTooltip.filter(Boolean).join("")}
                </div>`;
            },
        },
        xaxis: {
            type: "datetime",
            padding: {
                left: 10,
                right: 10,
            },
        },
    };

    const lineChartOptions = {
        chart: {
            type: "line",
            animations: {
                enabled: false,
            },
            toolbar: {
                tools: {
                    reset: false,
                },
            },
        },
        stroke: {
            width: 2,
        },
        noData: {
            text: "Loading...",
            align: "center",
        },
        tooltip: {
            x: {
                formatter: function (val) {
                    return moment
                        .utc(val)
                        .tz(timezone)
                        .format("DD/MM/YYYY HH:mm:ss");
                },
            },
            y: {
                formatter: function (
                    value,
                    { seriesIndex, dataPointIndex, w },
                ) {
                    const dataPoint =
                        w.config.series[seriesIndex].data[dataPointIndex];
                    if (!dataPoint) {
                        return `Net: ${value}`;
                    }
                    const { upstream, downstream } = dataPoint;
                    return `Upstream: ${upstream}\nDownstream: ${downstream}\n Net: ${value}`;
                },
            },
        },

        xaxis: {
            type: "datetime",
            title: {
                text: "Timestamp",
                style: {
                    color: "#000000",
                    fontSize: "15px",
                },
            },
            labels: {
                datetimeFormatter: {
                    year: "yyyy",
                    month: "MMM 'yy",
                    day: "dd MMM",
                    hour: "HH:mm",
                },
                datetimeUTC: false,
            },
            padding: {
                top: 0,
                right: 50,
                bottom: 0,
                left: 50,
            },
        },
    };

    const hourlyGraphOptions = {
        ...lineChartOptions,
        yaxis: {
            labels: {
                style: {
                    colour: "#000000",
                    fontSize: "15px",
                },
            },
            title: {
                text: "Hourly Counts",
                style: {
                    color: "#000000",
                    fontSize: "15px",
                },
            },
        },
    };

    // returns a series with properties hourlySeries,
    // cumulativeSeries, and confidenceIntervalSeries
    function initializeSeries(data) {
        const series = {
            cumulativeConfidenceIntervalSeries: [],
            cumulativeNetSeries: [],
            hourlySeries: [],
        };

        Object.values(data)
            .sort((a, b) =>
                a.device.displayName.localeCompare(b.device.displayName),
            )
            .forEach((deviceCount) => {
                series.cumulativeConfidenceIntervalSeries.push({
                    name: `${deviceCount.device.displayName} Bounds`,
                    type: "rangeArea",
                    data: data[deviceCount.device.id].cumulative.map(
                        (item) => ({
                            x: item.timestamp,
                            y: [
                                item.value[Direction.Net][CountType.LowerBound],
                                item.value[Direction.Net][CountType.UpperBound],
                            ],
                            predicted: item.value[Direction.Net][CountType.Predicted],

                        }),
                    ),
                });

                series.cumulativeNetSeries.push({
                    name: deviceCount.device.displayName,
                    type: "line",
                    data: data[deviceCount.device.id].cumulative.map(
                        (item) => ({
                            x: item.timestamp,
                            y: item.value[Direction.Net][CountType.Predicted],
                            upstream:
                                item.value[Direction.Upstream][
                                    CountType.Predicted
                                ],
                            downstream:
                                item.value[Direction.Downstream][
                                    CountType.Predicted
                                ],
                        }),
                    ),
                });

                series.hourlySeries.push({
                    name: deviceCount.device.displayName,
                    type: "line",
                    data: data[deviceCount.device.id].instant.map((item) => ({
                        x: item.timestamp,
                        y: Math.floor(
                            item.value[Direction.Net][CountType.Predicted],
                        ),
                        upstream: Math.floor(
                            item.value[Direction.Upstream][CountType.Predicted],
                        ),
                        downstream: Math.floor(
                            item.value[Direction.Downstream][
                                CountType.Predicted
                            ],
                        ),
                    })),
                });
            });

        return series;
    }

    const fetchSummaryTabData = async (seasonStartDate, endDate) => {
        const correctedDeviceCounts = {};

        setLoading(true);

        await Promise.all(
            devices.map(async (device) => {
                let fishCountsByHour = await v1TaglessService
                    .getDetections(device.id, {
                        startTimestamp: seasonStartDate.toISOString(),
                        endTimestamp: endDate.toISOString(),
                        interval: "1h",
                    })
                    .catch((error) => {
                        setErrorState({
                            hasError: true,
                            message: `unable to load detections data for ${device.displayName}`,
                        });
                    });

                if (fishCountsByHour) {
                    let pipelines = [
                        ...new Set(
                            fishCountsByHour.counts.map(
                                (item) => item.pipelineID,
                            ),
                        ),
                    ];
                    let pipelineMetrics = {};

                    await Promise.all(
                        pipelines.map(async (pipeline) => {
                            let pm =
                                await v1TaglessService.getPipelineMetrics(
                                    pipeline,
                                );
                            // pipelineMetrics represents a map where the key is a pipelineID and the value is a list of metrics.
                            // pipelineMetrics can be used to map the pipelineIDs from the detections to get their metric values.
                            pipelineMetrics[pipeline] = pm["data"]
                                ? pm["data"]["metrics"]
                                : [];
                            return pm;
                        }),
                    );

                    const correctedCounts = scalingBiasCorrection(
                        fishCountsByHour.counts,
                        new PipelineMetricMap(pipelineMetrics),
                    );
                    correctedDeviceCounts[device.id] = {
                        device: device,
                        instant: correctedCounts,
                        cumulative: cumulativeSum(correctedCounts),
                    };

                    const countsWithCumulative = calculateCumulativeValues(
                        fishCountsByHour.counts,
                    );

                    let deviceDataByHour = {
                        device: device,
                        countData: countsWithCumulative,
                        dayImagePath: null,
                        validated: !deviceIsValid(
                            device.accurateAsOf,
                            moment(seasonStartDate),
                        ),
                    };

                    let maxCountBucket = { imagePath: null };
                    if (deviceDataByHour.countData.length > 0) {
                        maxCountBucket = deviceDataByHour.countData.reduce(
                            (prev, curr) => {
                                return prev.total > curr.total ? prev : curr;
                            },
                        );
                    }

                    deviceDataByHour.dayImagePath = maxCountBucket.imagePath;
                }
            }),
        );

        setSeries(initializeSeries(correctedDeviceCounts));
        setBoundedData(correctedDeviceCounts);
        setLoading(false);
    };

    useEffect(() => {
        let seasonStartDate = moment(seasonStart).tz(timezone).startOf("day");
        let seasonEndDate = moment(seasonEnd).tz(timezone).endOf("day");
        setLoading(true);
        if (!devices) {
            setErrorState({
                hasError: true,
                message: `unable to load detections data for ${site.displayName}`,
            });
            return;
        }
        fetchSummaryTabData(seasonStartDate, seasonEndDate);
    }, [devices]);

    useEffect(() => {
        if (boundedData != undefined && Object.keys(boundedData).length !== 0) {
            let isBoundedDataHasCounts = false;
            const keys = Object.keys(boundedData);
            for (const key of keys) {
                if (
                    boundedData[key].cumulative.length !== 0 ||
                    boundedData[key].instant.length !== 0
                ) {
                    isBoundedDataHasCounts = true;
                }
            }
            setIsCSVExportEnabled(isBoundedDataHasCounts);
        }
    }, [boundedData]);

    return (
        <>
            {boundedData && !isLoading ? (
                <Box>
                    <Toolbar sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 } }}>
                        <Typography
                            sx={{ flex: "1 1 100%", color: "#043c4a" }}
                            variant="h4"
                            id="tableTitle"
                            component="div"
                        >
                            Season Summary
                        </Typography>
                        <CSVLink
                            data={getCSVDataFromBoundedData(
                                boundedData,
                                enableCI,
                            )}
                            filename={`${site.name}_season_report.csv`}
                            target="_blank"
                            onClick={() => {
                                return isCSVExportEnabled;
                            }}
                        >
                            <Tooltip title="Export Season Summary (CSV)">
                                <span>
                                    <Button
                                        sx={{
                                            background: "#4eafb2",
                                            "&:hover": {
                                                backgroundColor: "#043c4a",
                                                boxShadow: "none",
                                            },
                                        }}
                                        startIcon={<FileDownloadIcon />}
                                        disabled={!isCSVExportEnabled}
                                        variant="contained"
                                    >
                                        Export
                                    </Button>
                                </span>
                            </Tooltip>
                        </CSVLink>
                    </Toolbar>
                    <SummaryTable
                        data={Object.values(boundedData)
                            .sort((a, b) =>
                                a.device.displayName.localeCompare(
                                    b.device.displayName,
                                ),
                            )
                            .map((deviceCount) => {
                                return {
                                    device: deviceCount.device,
                                    boundedCount: deviceCount.cumulative.at(-1),
                                };
                            })}
                        showCI={enableCI}
                    />
                </Box>
            ) : (
                <div>Loading summary report...</div>
            )}
            <br />
            <Box className="FishcountsGraph">
                <Typography
                    variant="h4"
                    sx={{ color: "#043C4A" }}
                    component="div"
                >
                    Cumulative Counts
                </Typography>
                <Graph
                    options={cumulativeChartOptions}
                    series={[
                        ...(cumulativeNetSeries ?? []),
                        ...(enableCI
                            ? (cumulativeConfidenceIntervalSeries ?? [])
                            : []),
                    ]}
                ></Graph>
            </Box>
            <br />
            <Box className="FishcountsGraph">
                <Typography
                    variant="h4"
                    sx={{ color: "#043C4A" }}
                    component="div"
                >
                    Hourly Counts
                </Typography>
                <Graph
                    options={hourlyGraphOptions}
                    series={hourlySeries}
                ></Graph>
            </Box>
        </>
    );
};

export default SummaryReport;
