import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableContainer,
} from "@mui/material";
import { TableRowStyled } from "./TableStyled";
import {
    getCoreRowModel,
    useReactTable,
    flexRender,
} from "@tanstack/react-table";

export const DynamicTable = ({ columns, data }) => {
    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
    });

    return (
        <TableContainer
            component={Paper}
            sx={{ border: "1px solid #043c4a33", width: "100%" }}
        >
            <Table>
                <TableHead sx={{ textAlign: "center" }}>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <TableRowStyled key={headerGroup.id}>
                            {headerGroup.headers.map((header) => {
                                return (
                                    <TableCell
                                        key={`${headerGroup.id}=${header.id}`}
                                        colSpan={header.colSpan}
                                        sx={{ textAlign: "center" }}
                                    >
                                        {flexRender(
                                            header.column.columnDef.header,
                                            header.getContext(),
                                        )}
                                    </TableCell>
                                );
                            })}
                        </TableRowStyled>
                    ))}
                </TableHead>
                <TableBody>
                    {table.getRowModel().rows.map((row) => {
                        return (
                            <TableRowStyled key={row.id}>
                                {row.getVisibleCells().map((cell) => {
                                    return (
                                        <TableCell
                                            key={cell.id}
                                            sx={{ textAlign: "center" }}
                                        >
                                            {
                                            flexRender(
                                                cell.column.columnDef.cell,
                                                cell.getContext(),
                                            )}
                                        </TableCell>
                                    );
                                })}
                            </TableRowStyled>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
