import * as React from "react";
import "./Report.css";
import { Box, Container, Tab, Tabs } from "@mui/material";
import {
    MemoizedTabPanel,
    a11yProps,
} from "../../../components/TabPanel/TabPanel";
import { useEffect, useState } from "react";
import { v1TaglessService } from "../../../services/Services";
import DailyReport from "./DailyReport";
import SummaryReport from "./SummaryReport";
import { ConfidenceIntervalSwitch } from "./Switches/ConfidenceIntervalSwitch";

const CONFIDENCE_INTERVAL_LOCAL_STORAGE_KEY = "confidence_interval_status";

const Report = ({ setErrorState, site }) => {
    const [value, setValue] = useState(0);
    const [devices, setDevices] = useState([]);

    const [ciEnabled, setCIEnabled] = useState(
        localStorage.getItem(CONFIDENCE_INTERVAL_LOCAL_STORAGE_KEY) === "true",
    );

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const toggleConfidenceIntervals = (e) => {
        setCIEnabled(!ciEnabled);
        localStorage.setItem(
            CONFIDENCE_INTERVAL_LOCAL_STORAGE_KEY,
            `${!ciEnabled}`,
        );
    };

    // Use effect with site dependency
    useEffect(() => {
        const getDevicesForSite = async () => {
            let deviceResponse = await v1TaglessService
                .getDevicesBySiteID(site.id)
                .catch((error) => {
                    setErrorState({
                        hasError: true,
                        message: `unable to load detections data for ${site.displayName}`,
                    });
                });
            setDevices(deviceResponse);
        };

        getDevicesForSite();
    }, [site, setErrorState]);

    return (
        <Box sx={{ width: "100%" }}>
            <React.Fragment>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Container
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            width: "100%",
                            justifyContent: "space-between",
                            alignContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            aria-label="basic tabs example"
                        >
                            <Tab label="Summary" {...a11yProps(0)} />
                            <Tab label="Daily Report" {...a11yProps(1)} />
                        </Tabs>
                        {value === 0 ? (
                            <ConfidenceIntervalSwitch
                                checked={ciEnabled}
                                onChange={toggleConfidenceIntervals}
                            />
                        ) : null}
                    </Container>
                </Box>
                <MemoizedTabPanel value={value} index={0}>
                    <SummaryReport
                        devices={devices}
                        site={site}
                        setErrorState={setErrorState}
                        enableCI={ciEnabled}
                    />
                </MemoizedTabPanel>
                <MemoizedTabPanel value={value} index={1}>
                    <DailyReport
                        devices={devices}
                        site={site}
                        setErrorState={setErrorState}
                        activeTab={value}
                    />
                </MemoizedTabPanel>
            </React.Fragment>
        </Box>
    );
};

export default Report;
