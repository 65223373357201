import Container from "@mui/material/Container";
import { Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Site } from "../../types/Site";
import Report from "./Components/Report";
import { UserContext } from "../../providers/UserProvider";

interface Error {
  hasError: boolean;
  message: string;
}

interface Props {
  setErrorState(error: Error): void;
  site: Site;
}

const ReportLayout: React.FC<Props> = ({ setErrorState, site }: Props) => {
  useEffect(() => {
    console.log(`${site?.displayName ?? ""} set as the active site`);
  }, [site]);

  return (
    <Container sx={{ ml: "20%" }}>
      {site ? (
        <>
          <Typography
            className="SiteHeader"
            variant="h3"
            sx={{ color: "#043C4A", paddingTop: 5, paddingBottom: 3 }}
            component="div"
          >
            {site.displayName}
          </Typography>
          <Report setErrorState={setErrorState} site={site} />
        </>
      ) : null}
    </Container>
  );
};

export default ReportLayout;
