import { Component } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

interface ErrorStateProperties {
  hasError: boolean
  message: string
  setState(state: any): void
}

class ErrorBoundary extends Component<ErrorStateProperties> {

  handleClose = () => {
    this.props.setState({
      hasError: false,
      message: '',
    })
  };

  render() {
    if (this.props.hasError) {
      return (
        <Snackbar open={true} autoHideDuration={6000} onClose={this.handleClose} anchorOrigin={{"vertical": "top", 'horizontal': "center"}}>
          <MuiAlert elevation={6} variant="filled" onClose={this.handleClose} severity="error">
            {this.props.message}
          </MuiAlert>
        </Snackbar>
      );
    }
  }
}

export default ErrorBoundary;
