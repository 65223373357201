import { useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import { DatePicker as MUIDatePicker } from '@mui/x-date-pickers/DatePicker';

export const DatePicker = (props) => {
    const [value, setValue] = useState(moment())

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <MUIDatePicker
                label='Select a Date'
                defaultValue={ moment.tz(props.defaultValue, props.timezone) }
                value={ value } 
                onChange={(date) => { 
                    setValue(date)
                    props.onChange(date) 
                }}
            />
        </LocalizationProvider>
    )
};