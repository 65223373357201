import { CognitoIdToken, CognitoUser, CognitoUserPool, CognitoUserSession } from "amazon-cognito-identity-js";
import { ICognitoDriver } from "./CognitoDriver"; 
import { Auth } from 'aws-amplify';

export interface IAuthClient {
    login(username: string, password: string): Promise<void>
    logout(): void
    refreshSession(): Promise<void>
    getIdentityToken(): CognitoIdToken | null
    changePassword(oldPassword: string, newPassword: string): Promise<void>
    getUsername(): string | null
}

/**
 * AuthClient is a stateful wrapper around the ICognitoDriver.
 * AuthClient manages running instances of userPools, users, sessions,
 * and any other metadata needed to perform authorization logic.
 */
export default class AuthClient implements IAuthClient {
    authDriver: ICognitoDriver;
    protected userPool: CognitoUserPool;
    protected session: CognitoUserSession | undefined;
    protected user: CognitoUser | null;

    constructor(driver: ICognitoDriver, userPoolId: string, clientId: string) {
        this.authDriver = driver;
        const poolData = {
            UserPoolId: userPoolId,
            ClientId: clientId, 
        };
        this.userPool = new CognitoUserPool(poolData);
        this.user = this.userPool.getCurrentUser();
        
        if (this.user != null) {
            this.user.getSession((err: any, session: any ) => {
                if (err || !session.isValid()) {
                    return; 
                }
                this.session = session;
            });
        }
    }

    async login(username: string, password: string): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {    
            if (this.session?.isValid()) {
                resolve(undefined);
            }      
            
            this.user = new CognitoUser({
                Username: username,
                Pool: this.userPool,
            })

            try {
                this.session = await this.authDriver.login(this.user, password)
                resolve(undefined)
            } catch (error) {
                reject(error)
            }
        })
    }

    logout() {
        if (this.user) {
            this.authDriver.logout(this.user);
        }
        this.user = null
        this.session = undefined;
    }

    async refreshSession(): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            if (this.session && this.user) {
                try {
                    this.session = await this.authDriver.refreshSession(this.user, this.session.getRefreshToken())
                    resolve(undefined)
                } catch (error) {
                    reject(error)
                }
            }

            reject(new Error("cannot refresh session"))
        })
    }

    getIdentityToken(): CognitoIdToken | null {
        if (this.session) {
            return this.session.getIdToken()
        }
        return null
    }

    async changePassword(oldPassword: string, newPassword: string): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            if (this.user) {
                await this.authDriver.changePassword(this.user, oldPassword, newPassword)
                .then(() => {
                    resolve(undefined)
                })
                .catch((error) => {
                    reject(error)
                })
            }

            reject(new Error("not logged in"))
        })
    }

    getUsername(): string | null {
        if (this.user) {
            return this.user.getUsername();
        }
        return null;
    }
}