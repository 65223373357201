import { createContext, PropsWithChildren, useEffect, useState } from "react";
import { Site } from "../types/Site";
import { authService, v1TaglessService } from "../services/Services";

export interface IUserDetails {
    username: string | null;
}

export interface UserContextType {
    userDetails: IUserDetails | null;
    setUserDetails: (userDetails: IUserDetails | null) => void;
    allowedSites: Site[];
    timezone: string;
}

interface Props {
    setErrorState(error: any): void;
}

const UserContext = createContext<UserContextType | null>(null);

const UserProvider: React.FC<PropsWithChildren<Props>> = ({
    children,
    setErrorState,
}) => {
    const [userDetails, setUserDetails] = useState<IUserDetails | null>(
        authService.getIdentityToken()
            ? { username: authService.getUsername() }
            : null,
    );
    const [allowedSites, setAllowedSites] = useState<Site[]>([]);

    useEffect(() => {
        const loadAuthorizedSites = async () => {
            const token = authService.getIdentityToken();
            if (!token) {
                throw new Error("No auth token available");
            }
            const sites = await v1TaglessService.getSites().catch((error) => {
                throw error;
            });
            setAllowedSites(sites ? sites : []);
        };
        if (userDetails && userDetails != null) {
            loadAuthorizedSites().catch((error) => {
                setErrorState({
                    hasError: true,
                    message: "unable to load Sites",
                });
            });
        } else {
            setAllowedSites([]);
        }
    }, [userDetails, setErrorState]);

    return (
        <UserContext.Provider
            value={{
                userDetails,
                setUserDetails,
                allowedSites,
                timezone: "America/Halifax",
            }}
        >
            {children}
        </UserContext.Provider>
    );
};

export { UserProvider, UserContext };
