import { DynamicTable } from "../../../../components/Table/DynamicTable";
import { Direction } from "../../../../types/Direction";
import { ColumnDef, RowData } from "@tanstack/react-table";
import React from "react";
import { Device } from "../../../../types/Device";
import moment from "moment";

/**
 * A map of device counts, keyed by device name.
 * In directionalCounts, counts are keyed by Direction.
 * A count's value is its numerical count. GeneratedOn
 * is used to determine when to grey out cells.
 */
export type KeyedDeviceCountsMap = Record<string, {
        device: Device;
        directionalCounts: Record<string, {
                value: number;
                generatedOn: string;
            }>;
    }>;

// Extend the ColumnMeta interface with a function to apply conditional styling.
declare module "@tanstack/react-table" {
    interface ColumnMeta<TData extends RowData, TValue> {
        getStyle?: (cellValue: any, validatedOn: Date) => React.CSSProperties;
    }
}

const initializeColumns = (
    data: KeyedDeviceCountsMap,
): ColumnDef<KeyedDeviceCountsMap>[] => {
    const columns: ColumnDef<KeyedDeviceCountsMap, any>[] = [];

    const devices = [...new Set(Object.keys(data))].sort(
        (a: string, b: string): number => {
            const ordinalA = parseInt(a.split("-")[1], 10);
            const ordinalB = parseInt(b.split("-")[1], 10);
            return ordinalA - ordinalB;
        },
    );

    const order: Record<Direction, number> = {
        [Direction.Upstream]: 0,
        [Direction.Downstream]: 1,
        [Direction.Net]: 2,
    };

    devices.forEach((device: string) => {
        const directions = [
            ...new Set(Object.keys(data[device].directionalCounts)),
        ].sort((a, b): number => {
            return (
                order[a as keyof typeof Direction] -
                order[b as keyof typeof Direction]
            );
        });

        const deviceCols: ColumnDef<KeyedDeviceCountsMap, any>[] = [];

        directions.forEach((direction: string) => {
            deviceCols.push({
                accessorFn: (row) => {
                    return row[device].directionalCounts[
                        direction as keyof typeof Direction
                    ];
                },
                id: `${device}-${direction}`,
                header: () => <span>{`${direction}`}</span>,
                meta: {
                    getStyle: (
                        cellValue: { generatedOn: string },
                        validatedOn: Date,
                    ) => {
                        return moment(cellValue.generatedOn).isSameOrAfter(
                            validatedOn,
                        )
                            ? { color: "inherit" }
                            : { color: "#CCCCCC" };
                    },
                },
                cell: ({ row, getValue, column }) => {
                    const cellValue = getValue();

                    const validatedOn =
                        row.original[device].device.accurateAsOf;

                    const style = column.columnDef.meta?.getStyle?.(
                        cellValue,
                        validatedOn,
                    );

                    return <span style={style}>{cellValue.value}</span>;
                },
            });
        });

        columns.push({
            header: data[device].device.displayName,
            columns: deviceCols,
        });
    });

    return columns;
};

/** Properties for the DailySummaryTable */
interface Properties {
    data: KeyedDeviceCountsMap;
}

/** A component which displays a summary of daily counts. 
 * Data state is expected to be controlled by the parent.
 */
export const DailySummaryTable = (props: Properties) => {
    return (
        <DynamicTable
            columns={initializeColumns(props.data)}
            data={[props.data]}
        />
    );
};
