import { FormControlLabel, FormGroup, Switch } from "@mui/material";
import { ChangeEvent } from "react";

export const ConfidenceIntervalSwitch = ({
    checked,
    onChange,
}: {
    checked: boolean;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}) => {
    return (
        <FormGroup>
            <FormControlLabel
                control={
                    <Switch
                        onChange={onChange}
                        checked={checked}
                    />
                }
                label="Confidence Intervals"
                sx={{
                    marginRight: "0px",
                }}
            />
        </FormGroup>
    );
};
