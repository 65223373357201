import { AuthenticationDetails, CognitoAccessToken, CognitoIdToken, CognitoRefreshToken, CognitoUser, CognitoUserPool, CognitoUserSession } from "amazon-cognito-identity-js";

export interface Session {
    identityToken: CognitoIdToken,
    accessToken: CognitoAccessToken,
    refreshToken: CognitoRefreshToken,
}

export interface ICognitoDriver {
    login(user: CognitoUser, password: string): Promise<CognitoUserSession>
    logout(user: CognitoUser): void
    refreshSession(user: CognitoUser, token: CognitoRefreshToken): Promise<CognitoUserSession>
    changePassword(user: CognitoUser, oldPassword: string, newPassword: string): Promise<void>
}

export default class CognitoDriver implements ICognitoDriver {

    async login(user: CognitoUser, password: string): Promise<CognitoUserSession> {
        const authDetails = new AuthenticationDetails({
            Username: user.getUsername(),
            Password: password,
        })

        return new Promise((resolve, reject) => {
            user.authenticateUser(authDetails, {
                onSuccess: function (session: CognitoUserSession, _?: boolean | undefined) {
                    resolve(session)
                },
                onFailure: (error) => {
                    reject(error)
                }
            });
        })
    }

    logout(user: CognitoUser) {
        user.signOut()
    }

    async refreshSession(user: CognitoUser, token: CognitoRefreshToken): Promise<CognitoUserSession> {
        return new Promise((resolve, reject) => {
            user.refreshSession(token, (error, session: CognitoUserSession) => {
                if (error) {
                    reject(error)
                }
                resolve(session)
            })
        })
    }

    async changePassword(user: CognitoUser, oldPassword: string, newPassword: string): Promise<void> {
        return new Promise((resolve, reject) => {
            user.changePassword(oldPassword, newPassword, function (err, result) {
                if (err) {
                    reject(err)
                }
                resolve(undefined)
            })
        });
    }
}
