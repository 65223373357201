import './App.css';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';
import ReportLayout from './pages/ReportLayout/ReportLayout';
import ChangePassword from './pages/ChangePassword/ChangePassword';
import Login from './pages/Login/Login';
import PrivateRoutes from './utils/PrivateRoutes';
import { AppConfig } from './appConfig';
import { ThemeProvider, CssBaseline } from '@mui/material';
import { theme } from "./styles/Theme.styled"
import { UserProvider } from './providers/UserProvider';
import Toolbar from '@mui/material/Toolbar';
import { useState } from 'react';
import Notification from './components/Error/Notification';

function App({ config }) {

  const navigate = useNavigate();
  const [errorState, setErrorState] = useState({
    hasError: false,
    message: '',
  })

  const [selectedSite, setSelectedSite] = useState(null);

  const handleSiteSelect = (site) => {
    setSelectedSite(site)
    localStorage.setItem("selected_site" , site.name)
  }

  return (
    <AppConfig.Provider value={config}>
      <UserProvider setErrorState={setErrorState}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Navbar setSelectedSite={handleSiteSelect} />
          <Toolbar />
          <Notification hasError={errorState.hasError} message={errorState.message} setState={setErrorState} />
          <Routes>
            <Route path="/login" element={<Login setErrorState={setErrorState} />} />
            <Route element={<PrivateRoutes />}>
              <Route path="/" element={<ReportLayout setErrorState={setErrorState} site={selectedSite} key={selectedSite?.id}/>} index/>
              <Route path="/changePassword" element={<ChangePassword setErrorState={setErrorState} site={selectedSite} />} />
            </Route>
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </ThemeProvider>
      </UserProvider>
    </AppConfig.Provider>
  )
};

export default App;
