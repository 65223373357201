import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Container from "@mui/material/Container";
import Form from "react-bootstrap/Form";
import TextField from '@mui/material/TextField';
import './Login.css';
import { Separator } from "./Login.style";
import { HeaderPrimary } from "../../components/HeaderText/HeaderText.style";
import Button from '@mui/material/Button';
import Box from '@mui/material/Box'
import { UserContext } from "../../providers/UserProvider";
import { authService, v1TaglessService } from "../../services/Services";


export default function Login({ setErrorState }) {
    const { setUserDetails } = useContext(UserContext)
    const [ username, setUsername ] = useState("");
    const [ password, setPassword ] = useState("");
    const navigate = useNavigate('')

    const validateForm = () => {
        return username.length > 0 && password.length > 0;
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        authService.login(username, password)
            .then((_) => {
                v1TaglessService.updateBearerToken(authService.getIdentityToken())
                setUserDetails({username: username})
                navigate('/')
            })
            .catch((reason) => {
                setErrorState({
                    hasError: true,
                    message: reason.message,
                })
            })
    }

    // detect that user is already logged or not, if logged navigate to home page.
    useEffect(()=>{
        if(authService.getIdentityToken() !== null){
            navigate("/")
        }
    },[authService])

    return (
        <Container className="LoginContainer">
            <br />
            <Form onSubmit={handleSubmit}
                className="LoginForm"
            >
                <HeaderPrimary>
                    Sign In
                </HeaderPrimary>
                <br />
                <br />
                <Box
                    display="flex"
                    justifyContent="center"
                >
                    <TextField
                        label="Username"
                        type="text"
                        onChange={(e) => setUsername(e.target.value)}
                    />
                </Box>
                <Separator />
                <Box
                    display="flex"
                    justifyContent="center"
                >
                    <TextField
                        id="outlined-password-input"
                        label="Password"
                        type="password"
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </Box>
                <br />
                {/* <PrimaryButton type="submit" disabled={!validateForm()} text="Login" onSubmit={authenticate(username, password)}/> */}
                <Box
                    display="flex"
                    justifyContent="center"
                >
                    <Button variant="contained"
                        sx={{
                            bgcolor: "#043c4a",
                            height: "50px",
                            width: "50px",
                            color: "#ffffff",
                            ':hover': {
                                bgcolor: "#043c4a"
                            }
                        }}
                        disabled={!validateForm()}
                        type="submit"
                        className="PrimaryBtn"

                    >
                        Login
                    </Button>
                </Box>
                <Separator />
            </Form>
        </Container>
    );

}