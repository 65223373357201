import { DynamicTable } from "../../../../components/Table/DynamicTable";
import { BoundedCount, CountType } from "../../../../types/Count";
import { Direction } from "../../../../types/Direction";
import { ColumnDef } from "@tanstack/react-table";
import { useMemo } from "react";
import { Device } from "../../../../types/Device";

/** A map of counts, keyed first by
 * the CountType, followed by device name,
 * followed by direction, which yields the count.
 */
interface DeviceCounts {
    device: Device;
    boundedCount: BoundedCount;
}

const extractDeviceNames = (data: DeviceCounts[]): string[] => {
    return [...new Set(data.map((tdc) => tdc.device.displayName))];
};

const initializeColumns = (data: DeviceCounts[]): ColumnDef<Row, any>[] => {
    const columns: ColumnDef<Row, any>[] = [];

    columns.push({
        accessorKey: "type",
        cell: ({cell}) => {
            let typeStr = cell.getValue().replace(/([a-z])([A-Z])/g, '$1 $2');
            typeStr = typeStr.replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')
            return typeStr;
        },
        header: () => <span></span>,
    });

    const order: Record<Direction, number> = {
        [Direction.Upstream]: 0,
        [Direction.Downstream]: 1,
        [Direction.Net]: 2,
    };

    data.forEach((deviceCount) => {
        let directions : string[] = [];
        if(deviceCount.boundedCount){
            directions = Object.keys(deviceCount.boundedCount.value).sort(
                (a, b): number => {
                    return (
                        order[a as keyof typeof Direction] -
                        order[b as keyof typeof Direction]
                    );
                },
            );
        }
        

        const deviceCols: ColumnDef<Row, any>[] = [];
        directions.forEach((direction: string) => {
            deviceCols.push({
                accessorFn: (row) => {
                    return row[`${deviceCount.device.name}-${direction}`];
                },
                id: `${deviceCount.device.name}-${direction}`,
                cell: (info) => info.getValue(),
                header: () => <span>{`${direction}`}</span>,
            });
        });

        columns.push({
            header: deviceCount.device.displayName,
            columns: deviceCols,
        });
    });

    return columns;
};

interface Row {
    type: string;
    [key: string]: string | number;
}

const formatData = (data: DeviceCounts[]): Row[] => {
    const typedRows: Record<CountType, Row> = {
        [CountType.LowerBound]: { type: CountType.LowerBound },
        [CountType.Predicted]: { type: CountType.Predicted },
        [CountType.UpperBound]: { type: CountType.UpperBound },
    };
    
    data.forEach((deviceCount) => {
        if(deviceCount.boundedCount){
            Object.keys(deviceCount.boundedCount.value).forEach(
                (direction: string) => {
                    Object.keys(
                        deviceCount.boundedCount.value[
                            direction as keyof typeof Direction
                        ],
                    ).forEach((type) => {
                        typedRows[type as CountType][
                            `${deviceCount.device.name}-${direction}`
                        ] =
                            deviceCount.boundedCount.value[
                                direction as keyof typeof Direction
                            ][type as CountType];
                    });
                },
            );
        }
        
    });

    return Object.values(typedRows)
};

export interface Properties {
    data: DeviceCounts[];
    showCI: boolean;
}

/** Component that displays a high-level summary of the season's
 * Directional Counts.
 *
 * If props.showCI is true, then all confidence bounds will be displayed
 * in their own row. If false, then only the Predicted counts
 * will be displayed.
 *
 * Data state is expected to be controlled by the parent.
 */
export const SummaryTable = (props: Properties) => {
    const columns = useMemo<ColumnDef<Row, any>[]>(
        () => initializeColumns(props.data),
        [],
    );

    const display = [CountType.Predicted as string];
    if (props.showCI) {
        display.push(CountType.LowerBound as string);
        display.push(CountType.UpperBound as string);
    }

    const filteredData =
        formatData(props.data)?.filter((d) => display.includes(d.type)) ?? [];

    if (filteredData.length === 0) {
        return;
    }

    return <DynamicTable columns={columns} data={filteredData} />;
};
