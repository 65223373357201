import { useState } from 'react';

export const useDownloadFile = () => {
   
    const [isLoading, setIsLoading] = useState(false)
    const [downloadUrl , setDownloadUrl] = useState<string>()
    
    const downloadFile = async (fileName:string, url : string) => {
        setIsLoading(true)
        setDownloadUrl(url)
        const response = await fetch(url);
        const blob = await response.blob();
        const newFileName = fileName;

        // Create a link element
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = newFileName;

        // Append to the document and trigger the download
        document.body.appendChild(link);
        link.click();

        // Clean up and remove the link
        link?.parentNode?.removeChild(link);
        setIsLoading(false)
        setDownloadUrl(undefined)
      };
      
  return {
    downloadFile,
    downloading : isLoading,
    downloadUrl
  };
};
