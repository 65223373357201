import { useCallback, useState } from 'react';
import {
    MaterialReactTable,
    useMaterialReactTable,
} from 'material-react-table';

export const ExpandableTable = ({ columns: initialColumns, data: initialData, fetchSubRows }) => {

    const [data, setData] = useState(initialData);
    const [expandedRow, setExpandedRow] = useState(null);

    const handleExpand = useCallback(async (row) => {
        const rowId = row.id;
        if (expandedRow !== rowId) {
            setExpandedRow(rowId);
            if (!row.original.subRows) {
                const subRows = await fetchSubRows(row.original);
                setData((prevData) =>
                    prevData.map((item) =>
                        item.id === row.original.id ? { ...item, subRows } : item
                    )
                );
            }
        } else {
            setExpandedRow(null);
        }
    }, [expandedRow, fetchSubRows]);

    const table = useMaterialReactTable({
        columns: initialColumns || [],
        data,
        enableExpanding: true,
        enableExpandAll: false,
        enableGlobalFilter: false,
        enablePagination: false,
        enableBottomToolbar: false,
        enableTopToolbar: false,
        enableColumnFilters: false,
        enableSorting: false,
        enableStickyHeader: true,
        enableColumnActions: false,
        getSubRows: (row) => row.subRows,
        getRowCanExpand: (row) => !row.original.parantId,
        muiExpandButtonProps: ({ row }) => ({
            onClick: (e) => {
                e.stopPropagation();
                handleExpand(row);
            },
            'aria-label': expandedRow === row.id ? 'Collapse' : 'Expand',
            sx: { visibility: row.original.parentId ? 'hidden' : 'visible' },
        }),
        muiTableContainerProps: {
            sx: {
                maxHeight: '800px',
            },
        },
        muiTableBodyRowProps: ({ row }) => ({
            sx: {
                backgroundColor: row.original.parentId ? '#f5f5f5' : 'inherit'
            },
        }),
       
        state: { expanded: expandedRow ? { [expandedRow]: true } : {} }
    });

    return <MaterialReactTable table={table} />
};

