import { AmplifyError, ErrorMessage } from "../../types/Error";
import { IAuthClient } from "./AuthClient";

interface CognitoError {
    code: string
    message: string
}

function isCognitoError(object: any): object is CognitoError {
    return (
        (object as CognitoError).code !== undefined &&
        (object as CognitoError).message !== undefined
    )
}

/**
 * This is composed of an IAuthClient. The concrete implementation of this 
 * interface can change, while maintaining a stable authentication API regardless of the
 * technology we use to provide the authentication details (such as Cognito).
 */
export default class AuthService {
    authClient: IAuthClient;

    constructor(client: IAuthClient) {
        this.authClient = client;
    }

    /**
     * Logs in a user and establishes a session.
     * 
     * @param username - the username of the user attempting sign-in.
     * @param password - the password of the user attempting sign-in
     * @returns a Promise with either a defined Session or undefined data in the event of an error.
     */
    async login(username: string, password: string): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            await this.authClient.login(username, password)
                .then(() => {
                    resolve(undefined)
                })
                .catch((error) => {
                    if (isCognitoError(error)) {
                        if (error.code === AmplifyError.UserNotFoundException || error.code === AmplifyError.NotAuthorizedException) {
                            reject(new Error(ErrorMessage.InvalidLoginCredentials))
                        }
                    }

                    reject(new Error(ErrorMessage.Default))
                })
        })
    }

    /**
     * Logout a user and clears any active session or session artifacts.
     */
    logout() {
        this.authClient.logout();
    }

    /**
     * Fetches the active user's identity token.
     * 
     * @returns the identity token of the active user, if any, else undefined.
     */
    getIdentityToken(): string | null {
        const token = this.authClient.getIdentityToken()?.getJwtToken()
        if (token) return token
        return null
    }

    /**
     * Refreshes the active user's session. If the refresh fails then the
     * user is forcibly logged out.
     * 
     * @returns a Promise which may be rejected in the event of an error.
     */
    async refreshSession(): Promise<void> {
        return await this.authClient.refreshSession()
            .catch(() => { 
                this.authClient.logout();
                throw new Error(ErrorMessage.Default) 
            })
    }

    /**
     * Changes the password of the user.
     * 
     * @param oldPassword - the old password that the user has configured.
     * @param newPassword - the new password that the user wishes to use.
     * @throws an error if the password change was rejected.
     */
    async changePassword(oldPassword: string, newPassword: string) {

        return new Promise<void>(async (resolve, reject) => {
            await this.authClient.changePassword(oldPassword, newPassword)
            .then(() => {
                resolve(undefined)
            })
            .catch((error) => {
                if (isCognitoError(error)) {
                    if (error.code === AmplifyError.NotAuthorizedException) {
                        reject(new Error(ErrorMessage.InvalidChangePasswordCredentials))
                    }
                }

                reject(new Error(ErrorMessage.Default))
            })
        })
    }

    getUsername(): string | null {
        return this.authClient.getUsername();
    }
}