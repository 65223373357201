/**
 * A set of errors that need translating before they reach the user.
 */
export enum AmplifyError {
    NotAuthorizedException = "NotAuthorizedException",
    UserNotFoundException = "UserNotFoundException",
}

/**
 * A set of user-friendly errors.
 */
export enum ErrorMessage {
    InvalidLoginCredentials = "Invalid username or password",
    InvalidChangePasswordCredentials = "Password reset failed",
    Default = "Something went wrong",
}