const PasswordRequirementsError = () => {
    return (
        <span>
            Passoword should have at least:
            <li>
                Minimum 8 characters
            </li>
            <li>
                1 special character
            </li>
            <li>
                1 uppercase
            </li>
            <li>
                1 lowercase
            </li>
        </span>
    )
}

export default PasswordRequirementsError