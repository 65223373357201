import moment from "moment";
import { BoundedData } from "../../../types/BoundedData";
import { Direction } from "../../../types/Direction";
import { groupBy, forEach } from "lodash";
import { CountType } from "../../../types/Count";

type Count = {
    [key in Direction]: number | null;
};

// temporary interface until a TS refactor is done
// and a unified count interface is defined for reuse.
interface InputCount {
    upstreamCount: number | null;
    downstreamCount: number | null;
}

/** A list of counts to reduce down to a single, cumulative Count. */
export const reduceCumulative = (counts: InputCount[]): Count => {
    return counts.reduce(
        (prev, curr) => {
            return {
                [Direction.Upstream]: prev.Upstream + (curr.upstreamCount ?? 0),
                [Direction.Downstream]: prev.Downstream + curr.downstreamCount!,
                [Direction.Net]:
                    prev.Net +
                    (curr.upstreamCount ?? 0) -
                    (curr.downstreamCount ?? 0),
            };
        },
        {
            [Direction.Upstream]: 0,
            [Direction.Downstream]: 0,
            [Direction.Net]: 0,
        },
    );
};

export const getCSVDataFromBoundedData = (
    boundedData: BoundedData,
    showCI: boolean,
): string => {
    const rows: string[] = [];

    // Add the header row
    const header = ["Date"];
    const devices = Object.values(boundedData).sort((a, b) =>
        a.device.displayName.localeCompare(b.device.displayName),
    );

    // Add column names for each device
    if (showCI) {
        devices.forEach((device) => {
            header.push(
                `${device.device.displayName} upstream lower`,
                `${device.device.displayName} upstream predicted`,
                `${device.device.displayName} upstream upper`,
                `${device.device.displayName} downstream lower`,
                `${device.device.displayName} downstream predicted`,
                `${device.device.displayName} downstream upper`,
                `${device.device.displayName} net lower`,
                `${device.device.displayName} net predicted`,
                `${device.device.displayName} net upper`,
                `${device.device.displayName} net cumulative lower`,
                `${device.device.displayName} net cumulative predicted`,
                `${device.device.displayName} net cumulative upper`,
            );
        });
    } else {
        devices.forEach((device) => {
            header.push(
                `${device.device.displayName} upstream`,
                `${device.device.displayName} downstream`,
                `${device.device.displayName} net`,
                `${device.device.displayName} net cumulative`,
            );
        });
    }

    rows.push(header.join(","));

    // Prepare an array to hold the rows of data for each timestamp/hour combination
    const dataRows: Record<string, string[]> = {};

    // Iterate over each device in BoundedData
    devices.forEach((device) => {
        const cumulativeByDate = new Map(
            device.cumulative.map((count) => [
                moment(count.timestamp).format("MM/DD/YYYY"),
                count,
            ]),
        );

        const groupedByDate = groupBy(device.instant, (boundCount) => {
            return moment(boundCount.timestamp).format("MM/DD/YYYY");
        });

        forEach(groupedByDate, (boundedCounts, date) => {
            if (!dataRows[date]) {
                dataRows[date] = [date];
            }

            const cumulativeCounts = boundedCounts.reduce(
                (acc, item) => {
                    acc.value.Upstream.LowerBound +=
                        item.value.Upstream[CountType.LowerBound] ?? 0;
                    acc.value.Upstream.Predicted +=
                        item.value.Upstream.Predicted ?? 0;
                    acc.value.Upstream.UpperBound +=
                        item.value.Upstream[CountType.UpperBound] ?? 0;
                    acc.value.Downstream.LowerBound +=
                        item.value.Downstream[CountType.LowerBound] ?? 0;
                    acc.value.Downstream.Predicted +=
                        item.value.Downstream.Predicted ?? 0;
                    acc.value.Downstream.UpperBound +=
                        item.value.Downstream[CountType.UpperBound] ?? 0;
                    acc.value.Net.LowerBound +=
                        item.value.Net[CountType.LowerBound] ?? 0;
                    acc.value.Net.Predicted += item.value.Net.Predicted ?? 0;
                    acc.value.Net.UpperBound +=
                        item.value.Net[CountType.UpperBound] ?? 0;

                    return acc;
                },
                {
                    value: {
                        Upstream: {
                            LowerBound: 0,
                            Predicted: 0,
                            UpperBound: 0,
                        },
                        Downstream: {
                            LowerBound: 0,
                            Predicted: 0,
                            UpperBound: 0,
                        },
                        Net: { LowerBound: 0, Predicted: 0, UpperBound: 0 },
                    },
                },
            );
            const cumulative = cumulativeByDate.get(date);

            if (showCI) {
                dataRows[date].push(
                    Math.floor(
                        cumulativeCounts.value.Upstream.LowerBound,
                    ).toString(),
                    Math.floor(
                        cumulativeCounts.value.Upstream.Predicted,
                    ).toString(),
                    Math.floor(
                        cumulativeCounts.value.Upstream.UpperBound,
                    ).toString(),
                    Math.floor(
                        cumulativeCounts.value.Downstream.LowerBound,
                    ).toString(),
                    Math.floor(
                        cumulativeCounts.value.Downstream.Predicted,
                    ).toString(),
                    Math.floor(
                        cumulativeCounts.value.Downstream.UpperBound,
                    ).toString(),
                    Math.floor(
                        cumulativeCounts.value.Net.LowerBound,
                    ).toString(),
                    Math.floor(cumulativeCounts.value.Net.Predicted).toString(),
                    Math.floor(
                        cumulativeCounts.value.Net.UpperBound,
                    ).toString(),
                    Math.floor(
                        cumulative?.value.Net[CountType.LowerBound] ?? 0,
                    ).toString(),
                    Math.floor(cumulative?.value.Net.Predicted ?? 0).toString(),
                    Math.floor(
                        cumulative?.value.Net[CountType.UpperBound] ?? 0,
                    ).toString(),
                );
            } else {
                dataRows[date].push(
                    Math.floor(
                        cumulativeCounts.value.Upstream.Predicted,
                    ).toString(),
                    Math.floor(
                        cumulativeCounts.value.Downstream.Predicted,
                    ).toString(),
                    Math.floor(cumulativeCounts.value.Net.Predicted).toString(),
                    Math.floor(cumulative?.value.Net.Predicted ?? 0).toString(),
                );
            }
        });
    });

    // Sort the data rows by date
    const sortedDataKeys = Object.keys(dataRows).sort((a, b) => {
        return moment(a, "MM/DD/YYYY").isBefore(moment(b, "MM/DD/YYYY"))
            ? -1
            : 1;
    });

    // Add each sorted row to the CSV rows array
    sortedDataKeys.forEach((key) => {
        rows.push(dataRows[key].join(","));
    });

    // Return the CSV string
    return rows.join("\n");
};
