import Axios, { AxiosInstance } from "axios";
import { authService } from "../Services";


export class ApiConfiguration {
    // accessToken to be shared across all requests configured with the same axiosInstance.
    accessToken?: string;

    // base path of the url to prepend to all requests made with the same axiosInstance.
    baseUrl?: string;

    // configurable api version path.
    version?: string;
}

export type HttpHeaders = Record<string, string>;

export interface RequestConfig {
    headers: HttpHeaders;
}

export interface IApiClient {
    updateBearerToken(token: string): void
    get<TResponse>(path: string, config?: any): Promise<TResponse>
};

/**
 * Wraps an axios instance. This class can be used to setup and handle common
 * configuration for a single service. All requests routed through this wrapper will 
 * be provided with the same baseURL and authorization tokens.
 */
export default class ApiClient implements IApiClient {
    private client: AxiosInstance

    protected createAxiosClient(config: ApiConfiguration): AxiosInstance {
        const axiosClient = Axios.create({
            baseURL: config.baseUrl + (config.version ? `/${config.version}` : ""),
            responseType: 'json' as const,
            headers: {
                'Content-Type': 'application/json',
                
            }
        })

        axiosClient.interceptors.request.use(
            (config) => {
                const token = authService?.getIdentityToken();
                if (token) {
                    config.headers.Authorization = `Bearer ${token}`;
                }
                return config;
            },
            (error) => {
                return Promise.reject(error);
            }
        );

        axiosClient.interceptors.response.use(
            response => {
                return response
            },
            async (error) => {
                const originalRequest = error.config;

                if (error.response && error.response.status === 401 && !originalRequest._retry) {
                    originalRequest._retry = true;
                    const sessionRefreshPromise = authService?.refreshSession()
                    await sessionRefreshPromise?.catch(console.log)
                    const newToken = authService?.getIdentityToken();
                    if (newToken) {
                        originalRequest.headers.Authorization = `Bearer ${newToken}`;
                    }
                    return axiosClient(originalRequest)
                }

                throw error;
            }
        );

        return axiosClient
    }

    constructor(config: ApiConfiguration) {
        this.client = this.createAxiosClient(config)

        const token = authService?.getIdentityToken();
        if (token) {
            this.updateBearerToken();
        }
    }
    updateBearerToken(): void {
        const token = authService?.getIdentityToken();
        if (token) {
            this.client.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        }
    }

    async get<TResponse>(path: string, config?: any): Promise<TResponse> {
        const requestConfig =  {
            ...config,
            headers: {
                ...(config?.headers || {} )
            }
        }
        const response = await this.client.get<TResponse>(path, requestConfig).catch((error) => {
            throw error
        });
        return response.data;
    }
};