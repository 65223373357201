import { Typography } from "@mui/material"
import { ReactNode } from "react"

interface PlaceholderProps {
    height: string | number, // height of placeholder component
    width: string | number, // width of placeholder component
    backgroundColor: string // background color of placeholder component
    textColor: string // text color
    text: ReactNode // React text component custom styling can be rendered 
}

/**
 * 
 * @param props {@link PlaceholderProps}
 * @returns Placeholder component using div and {@link Typography}
 */
export const Placeholder = ({
    height,
    width,
    backgroundColor,
    textColor,
    text
}: PlaceholderProps) => {
    return <div style={{ height: height, width, display: 'flex', justifyContent: "center", alignContent: "center", alignItems: "center", backgroundColor: backgroundColor }}>
        <Typography variant="h3" style={{ color: textColor, textAlign: "center" }}>{text}</Typography>
    </div>
}